/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@font-face {
  font-family: "ariblk";
  src: local("ariblk"), url(./assets/fonts/ariblk.ttf) format("truetype");
}
@font-face {
  font-family: "FuturaLT-CondensedOblique";
  src: local("FuturaLT-CondensedOblique"), url(./assets/fonts/FuturaLT-CondensedOblique.ttf) format("truetype");
}

@font-face {
  font-family: "FuturaLT-Condensed";
  src: local("FuturaLT-Condensed"), url(./assets/fonts/FuturaLT-Condensed.ttf) format("truetype");
}

.example-spacer {
    flex: 1 1 auto;
  }

  .c-white{
      color: white;
  }

  .pb-1 {
    padding-bottom: 0.5rem !important;
  }
  
  .pb-2 {
    padding-bottom: 1rem !important;
  }
  .pb-3 {
    padding-bottom: 1.5rem !important;
  }
  .pb-4 {
    padding-bottom: 2rem !important;
  }
  
  .pt-2{
    padding-top: 1rem;
  }
  .pt-3{
    padding-top: 1.5rem;
  }
  .pt-4{
    padding-top: 2rem;
  }
  
  .pr-1 {
    padding-right: 0.5rem !important;
  }
  
  .py-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  
  .mb--1 {
    margin-bottom: -0.5rem !important;
  }
  .mb--2 {
    margin-bottom: -1rem !important;
  }
  .mb--3 {
    margin-bottom: -1.5rem !important;
  }
  
  .mb-0 {
    margin-bottom: 0px !important;
  }
  
  .mb-1 {
    margin-bottom: 0.5rem;
  }
  
  .mb-2 {
    margin-bottom: 1rem !important;
  }
  .mb-3{
    margin-bottom: 1.5rem !important;
  }
  
  .ml-2 {
    margin-left: 1rem !important;
  }
  
  .ml-1 {
    margin-left: 0.5rem !important;
  }
  
  .mx-1 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .mx-2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mx-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-4 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .my-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  
  .mr-2 {
    margin-right: 1rem !important;
  }
  
  .mr-1 {
    margin-right: 0.5rem !important;
  }
  
  .mt-1 {
    margin-top: 0.5rem !important;
  }
  .mt-2 {
    margin-top: 1rem !important;
  }
  .mt-3 {
    margin-top: 1.5rem !important;
  }